<template>
  <div>
    <!-- Buttons -->
    <crud-button
      :onClickList="fetchTeklifList"
      :showAddNew="false"
    />
    <!-- Filters -->
    <teklif-filters />
    <b-card no-body class="p-2">
      <DxDataGrid
        id="gridContainer"
        :height="600"
        :ref="dataGridRefName"
        :data-source="teklifList"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :hover-state-enabled="true"
        key-expr="id"
        @rowDblClick="onTeklifSelect"
      >
        <DxFilterRow :visible="true" :apply-filter="currentFilter" />
        <DxHeaderFilter :visible="true" />
        <DxPaging :enabled="false" />
        <DxSearchPanel :visible="true" :width="240" :placeholder="$t('Ara...')" />
        <DxColumn
          data-field="teklifDurum"
          cell-template="completeStatusTemplate"
          caption="Durum"
        />
        <DxColumn :width="100" data-field="branchCode" caption="İşyeri Kodu" />
        <DxColumn
          :width="120"
          data-field="docDateJS"
          caption="Belge Tarih"
          alignment="right"
          data-type="date"
          sort-order="desc"
        />
        <DxColumn data-field="docNo" caption="Belge No" />
        <DxColumn data-field="docTraCode" caption="Hareket Kodu" />
        <DxColumn data-field="entityCode" caption="Cari Kodu" />
        <DxColumn data-field="entityName" caption="Cari Adı" />
        <DxColumn data-field="offerStatus" caption="Teklif Durumu" />
        <DxColumn data-field="purchaseSales" caption="Alış/Satış" />
        <DxColumn data-field="salesPersonName" caption="Satış Personel" />
        <template #completeStatusTemplate="{ data }">
          <div>
            <b-badge v-show="data.value === 0"> {{ $t('Beklemede') }} </b-badge>
            <b-badge variant="primary" v-show="data.value === 1"> {{ $t('Onaylandı') }}</b-badge>
            <b-badge variant="success" v-show="data.value === 2"> {{ $t('Sipariş Oluşturuldu') }}</b-badge>
            <b-badge variant="danger" v-show="data.value === 3"> {{ $t('Reddedildi') }}</b-badge>
          </div>
        </template>
        <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
        <template #masterDetailTemplate="{ data: teklifData }">
          <teklif-detail :teklifDetayData="teklifData" />
        </template>
      </DxDataGrid>
    </b-card>
  </div>
</template>

<script>
import { DxDataGrid, DxPaging, DxColumn, DxSearchPanel, DxMasterDetail, DxHeaderFilter, DxFilterRow } from 'devextreme-vue/data-grid';
import { locale } from 'devextreme/localization';
import CrudButton from '@/components/CrudButton.vue';
import TeklifDetail from './TeklifDetail.vue';
import TeklifFilters from './TeklifFilters.vue';

export default {
  components: {
    CrudButton,
    DxMasterDetail,
    TeklifDetail,
    TeklifFilters,
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxHeaderFilter,
    DxFilterRow,
  },
  data() {
    return {
      currentFilter: 'auto',
      dataGridRefName: 'dataGrid',
    };
  },
  computed: {
    teklifList() {
      return this.$store.getters['teklifYonetim/getTeklifs'];
    },
  },
  methods: {
    fetchTeklifList() {
      this.$store.dispatch('teklifYonetim/fetchTeklifs');
    },

    onTeklifSelect(data) {
      this.$router.push({ name: 'management-teklif-islem', params: { id: data.key } })
    },
  },
  mounted() {
    this.fetchTeklifList();
  },
  created() {
    locale('tr')
  },
};
</script>

<style scoped>
#gridContainer {
  height: 440px;
}

.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
}
</style>
